import { Link } from 'react-router-dom';

import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import SailentFeaturesComp from '../salient-features/SailentFeaturesComp';
import { CDN_URL } from '../../../../config/config';

const ProductsListComp = ({productsJson}) => {
    let products = productsJson.map((p) => {
        return (<div className='col-12 col-md-6 col-xl-4' key={p.key}>
            <div className='product w-100 bg-white shadow rounded-3 overflow-hidden position-relative border'>
                <div className='img py-3 position-relative shadow-sm'><img src={`${CDN_URL}${p.product_image}`} className="img-fluid" alt={p.title} /></div>
                <div className='content p-4'>
                    <div className='title fw-bold'>{p.title}</div>
                    <div className='desc mb-2'>{p.desc.substring(0, 120)+'...'}</div>
                </div>
                <Link to={'/products/'+p.key} className='view-more position-absolute w-100 h-100 d-flex align-items-center justify-content-center top-0 left-0'>
                    <div className='icon rounded-circle d-flex align-items-center justify-content-center'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"/></svg></div>
                </Link>
            </div>
        </div>);
    });

    return (     
        <>
        <DefaultSectionComp className="section light bg-img products-list text-center">
            <div className='container'>
                <div className='products'>
                    <div className='row gy-5'>{products}</div>
                </div>
            </div>
        </DefaultSectionComp>
        <DefaultSectionComp className="bg-dark accessories">
            <div className='container'>
                <div className='row'>
                    <div className='heading'>
                        <h3 className='text-white text-center'>Product Accessories</h3>
                        <p className='text-center'>Vnext’s products have unmatched quality and in order to preserve this quality we recently introduced our very own line of product accessories. <br/> You can be rest assured that your installation will be sturdy and long lasting.</p>
                    </div>
                    <div className='row tgrid-system gy-4'>
                        <div className='heading'>
                            <h4 className='mb-0 text-white'>Tee Grid System</h4>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/main-t.png'} alt="Product"/>
                                </div>
                                <div className='title'>
                                    <h5>Main T</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/cross-t.png'} alt="Product"/>
                                </div>
                                <div className='title'>
                                    <h5>Cross T</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/wall-angle.png'} alt="Product"/>
                                </div>
                                <div className='title'>
                                    <h5>Wall Angle</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row tgrid-system gy-4'>
                        <div className='heading'>
                            <h4 className='mb-0 text-white'>GI Partition System</h4>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/floor-and-ceiling-channel.png'} alt="Product"/>
                                </div>
                                <div className='title'>
                                    <h5>Floor & Ceiling Channel</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/metal-stud.png'} alt="Product"/>
                                </div>
                                <div className='title'>
                                    <h5>Metal Stud</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row tgrid-system gy-4'>
                        <div className='heading'>
                            <h4 className='mb-0 text-white'>G.I. Concealed Grid Suspended Ceiling System</h4>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/intermediate-channel.png'} alt="Product"/>
                                </div>
                                <div className='title'>
                                    <h5>Intermediate Channel</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/perimeter-channel.png'} alt=""/>
                                </div>
                                <div className='title'>
                                    <h5>Perimeter Channel</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/ceiling-section.png'} alt=""/>
                                </div>
                                <div className='title'>
                                    <h5>Ceiling section</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                            <div className='tgrid-system-item rounded text-center py-4 h-100'>
                                <div className='img mb-4'>
                                    <img src={CDN_URL+'visaka/vnext/website/assets/images/accessories/ceiling-angle.png'} alt=""/>
                                </div>
                                <div className='title'>
                                    <h5>Ceiling Angle</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row text-center justify-content-center mt-5'>
                        <Link to="/products/vframes" className='btn w-auto rounded-1 py-2 px-4 text-uppercase'>Know More</Link>
                    </div>
                </div>
            </div>
        </DefaultSectionComp>
        <SailentFeaturesComp/>
        </>
    );
}
export default ProductsListComp;