import React from 'react';
import ReactImageGallery from 'react-image-gallery';
import { CDN_URL } from '../../../../config/config';

const ApplicationDetailsComp = ({ applicationDetails }) => {
  const galleryItems = applicationDetails.gallery.map(item => ({
    original: `${CDN_URL}${item.original}`, 
    thumbnail: `${CDN_URL}${item.thumbnail || item.thumbnail}`
  }));

  return (
    <div className='application-details'>
      <div className='images mb-5'>
        <ReactImageGallery items={galleryItems} />
      </div>
      <div className='title'>
        <h4 className='text-capitalize'>{applicationDetails.title}</h4>
        <p className='lh-lg'>{applicationDetails.desc}</p>
      </div>
    </div>
  );
};

export default ApplicationDetailsComp;
