import react from 'react';
import { Link } from 'react-router-dom';

import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import { CDN_URL } from '../../../../config/config';

const CoveragesDetails = ({cDetails}) => {
                        
console.log(cDetails);

    
    const convertJSX = htmlData => {
        return htmlData.map((h, hIndex)=>{
            let attributeObj = {};
            const {attributes, localName, childNodes, nodeValue} = h;
            if(attributes){
                Array.from(attributes).forEach(attribute => {
                    if(attribute.name === "style"){
                        let styleAttributes = attribute.nodeValue.split(";");
                        let styleObj = {};
                        styleAttributes.forEach(attribute => { let [key, value] = attribute.split(":"); styleObj[key] = value; });
                        attributeObj[attribute.name] = styleObj;
                    } else if(attribute.name === "class") { attributeObj['className'] = attribute.nodeValue; }
                    else { attributeObj[attribute.name] = attribute.nodeValue; }
                });
            }
            attributeObj['key'] = hIndex;
            return localName ? react.createElement(localName, attributeObj, childNodes && Array.isArray(Array.from(childNodes)) ? convertJSX(Array.from(childNodes)) : [] ) : nodeValue;
        });
    }
    
    return (<>
        <DefaultSectionComp className="coverage-details">
            <div className="container">
                <div className="row">
                    <div className='col-12'>
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                        <Link to={'/coverages'} className='back fs-5 d-flex align-items-center gap-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16 256a240 240 0 1 1 480 0A240 240 0 1 1 16 256zm496 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zm-381.7-5.7l-5.7 5.7 5.7 5.7 104 104 5.7 5.7L251.3 360l-5.7-5.7L155.3 264 376 264l8 0 0-16-8 0-220.7 0 90.3-90.3 5.7-5.7L240 140.7l-5.7 5.7-104 104z"/></svg>Back</Link>
                        <div className='date fs-5 d-flex align-items-center gap-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M496 256A240 240 0 1 1 16 256a240 240 0 1 1 480 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM248 104V256c0 2.7 1.3 5.2 3.6 6.7l96 64c3.7 2.5 8.6 1.5 11.1-2.2s1.5-8.6-2.2-11.1L264 251.7V104c0-4.4-3.6-8-8-8s-8 3.6-8 8z"/></svg>{cDetails.date}</div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="image mb-5"><img src={`${CDN_URL}${cDetails.coverage_image}`} className='img-fluid w-100' alt="" /></div>
                </div>
                <div className="col-12">
                    {cDetails.content.list.map((c, cIndex) => {
                        let descData = new DOMParser().parseFromString(c.desc, 'text/html').body.childNodes;
                        return  <div key={cIndex} className="col-12"><div className="title"><h5 className='mb-2'>{c.title}</h5></div><div className="desc lh-lg">{convertJSX(Array.from(descData))}</div></div>
                    })}
                </div>
            </div>
        </div>
        </DefaultSectionComp>
    </>);
}

export default CoveragesDetails