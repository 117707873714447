import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PageResetComp from '../../website/common/page-reset/PageResetComp';
import { API_URL,CAMPAIGN_ID_VNEXTLANDING } from '../../../config/config';

const VnextSolutionsFormComp = () => {
    const [regional, setRegional] = useState([]);
    const [district, setDistrict] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const formik  = useFormik({
        initialValues:{
            name:"",
            email:"",
            phone_number:"",
            state:"",
            district:"",
            city:"",
            category:"",
            campaign_id:""
        },
        onSubmit:(values)=>{
            const formData = new FormData(); 
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("phone_number", values.phone_number);
            formData.append("state", values.state);
            formData.append("district", values.district);
            formData.append("city", values.city);
            formData.append("category", values.category);
            formData.append("campaign_id", CAMPAIGN_ID_VNEXTLANDING); 
            setIsLoading(true);
            axios.post(`${API_URL}vnext/enquiries/create`, formData)
            .then(response=>
                {
                    formik.resetForm();
                    response.data.status === 200 ? navigate('/success') : navigate('/error')
                    setIsLoading(false)
                }
            )
            .catch(err=>console.log(err))
        },
        validate:(values)=>{
            let errors = {};
            if(!values.name){
                errors.name="Full Name is required"
            }else if(values.name.length < 4){
                errors.name="Name Should be Minimum 3 letters"
            }
            else if (!/^[A-Za-z\s]+$/.test(values.name)) {
                errors.name = "Name should contain only letters";
            }
            if(!values.email){
                errors.email="Email is required"
            }
            else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid Email address';
            }
            if(!values.phone_number){
                errors.phone_number="Mobile Number is required"
            }
            else if(values.phone_number.length!==10){
                errors.phone_number = 'Mobile Number Must Be 10 Digits';
            }
            else if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.phone_number)) {
                errors.phone_number = 'Please Enter Numbers format only';
            }
            
            if(!values.category){
                errors.category="Category is required"
            }
            if(!values.state){
                errors.state="State is required"
            }
            if(!values.district){
                errors.district="District is required"
            }
            if(!values.city){
                errors.city="City is required"
            }
            return errors;
        }
     });

    const stateURL = "https://d2tm70q7nw2sv.cloudfront.net/assets/locations/json/indianStatesCities.json";
    const fetchStateDistrictInfo = async () => {
        const stateDistrictResponse = await fetch(stateURL);
        const stateDistrictResponseData = await stateDistrictResponse.json();
        setRegional(stateDistrictResponseData);
    }
    const handleStateDistrict = (e) => {
        const selectedState = e.target.value;
        const getDistrict = regional.filter((distitem) => distitem.State === selectedState);
        setDistrict(getDistrict);
        formik.setFieldValue('state', selectedState);
    }
    useEffect(() => {
        fetchStateDistrictInfo();
    }, [])

  return (
    <>  
        {isLoading && <PageResetComp />}
        <div className='shadow p-5 rounded'>
            <legend className='text-center mb-3'>Talk to our experts</legend>
            <form autoComplete='off' onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                    <div className="position-relative">
                        <input type="text" className="form-control rounded-0"  value={formik.values.name} onChange={formik.handleChange} name="name" placeholder="Your Name" />
                        <div className='icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c61.8 0 112 50.2 112 112s-50.2 112-112 112-112-50.2-112-112S194.2 32 256 32m128 320c52.9 0 96 43.1 96 96v32H32v-32c0-52.9 43.1-96 96-96 85 0 67.3 16 128 16 60.9 0 42.9-16 128-16M256 0c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144S335.5 0 256 0zm128 320c-92.4 0-71 16-128 16-56.8 0-35.7-16-128-16C57.3 320 0 377.3 0 448v32c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-32c0-70.7-57.3-128-128-128z"/></svg>
                        </div>
                    </div>
                    {formik.errors.name?<span className='text-danger mt-2 d-block'>{formik.errors.name}</span> : null}
                </div>
                <div className="mb-3">
                    <div className="position-relative">
                        <input type="text" className="form-control rounded-0"  maxLength={10} value={formik.values.phone_number} onChange={formik.handleChange} name="phone_number" placeholder="Phone number"/>
                        <div className='icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M493.1 351.3L384.7 304.8a31.4 31.4 0 0 0 -36.5 8.9l-44.1 53.9A350 350 0 0 1 144.5 208l53.9-44.1a31.4 31.4 0 0 0 8.9-36.5l-46.5-108.5A31.3 31.3 0 0 0 125 .8L24.2 24.1A31.1 31.1 0 0 0 0 54.5C0 307.8 205.3 512 457.5 512A31.2 31.2 0 0 0 488 487.7L511.2 387a31.2 31.2 0 0 0 -18.1-35.7zM456.9 480C222.4 479.7 32.3 289.7 32.1 55.2l99.6-23 46 107.4-72.8 59.5C153.3 302.3 209.4 358.6 313 407.2l59.5-72.8 107.4 46z"/></svg>
                        </div>
                    </div>
                    {formik.errors.phone_number? <span className='text-danger mt-2 d-block'>{formik.errors.phone_number}</span> : null}
                </div>
                <div className="mb-3">
                    <div className='position-relative'>
                        <input type="text" className="form-control rounded-0"  value={formik.values.email} onChange={formik.handleChange} name="email" placeholder="Email Address" />
                        <div className='icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2 .4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4 .3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"/></svg>
                        </div>
                    </div>
                    {formik.errors.email? <span className='text-danger mt-2 d-block'>{formik.errors.email}</span> : null}
                </div>
                <div className="mb-3">
                    <div className='position-relative'>
                        <select className="form-select form-control rounded-0"  value={formik.values.category} onChange={formik.handleChange} name="category"> 
                            <option value="">Select Category</option>
                            <option value="Architect">Architect</option>
                            <option value="Interior">Interior</option>
                            <option value="Builder">Builder</option>
                            <option value="Contractor">Contractor</option>
                            <option value="Dealership">Dealership</option>
                            <option value="Item enquiry">Item Enquiry</option>
                            <option value="Order enquiry">Order enquiry</option>
                            <option value="Others">Others</option>
                        </select>
                        <div className='icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M145.4 207a8 8 0 0 0 -11.4 0l-71 71-39-39a8 8 0 0 0 -11.3 0L1.4 250.3a8 8 0 0 0 0 11.3l56 56a8 8 0 0 0 11.3 0l88-88a8 8 0 0 0 0-11.3zM62.9 384c-17.7 0-32.4 14.3-32.4 32s14.7 32 32.4 32a32 32 0 0 0 0-64zm82.4-337A8 8 0 0 0 134 47l-71 71-39-39a8 8 0 0 0 -11.3 0L1.4 90.3a8 8 0 0 0 0 11.3l56 56a8 8 0 0 0 11.3 0l88-88a8 8 0 0 0 0-11.3zM503 400H199a8 8 0 0 0 -8 8v16a8 8 0 0 0 8 8h304a8 8 0 0 0 8-8v-16a8 8 0 0 0 -8-8zm0-320H199a8 8 0 0 0 -8 8v16a8 8 0 0 0 8 8h304a8 8 0 0 0 8-8V88a8 8 0 0 0 -8-8zm0 160H199a8 8 0 0 0 -8 8v16a8 8 0 0 0 8 8h304a8 8 0 0 0 8-8v-16a8 8 0 0 0 -8-8z"/></svg>
                        </div>
                    </div>
                    {formik.errors.category? <span className='text-danger mt-2 d-block'>{formik.errors.category}</span> : null}
                </div>
                <div className='mb-3'>
                    <select className="form-select rounded-0" name="state" onChange={handleStateDistrict}  value={formik.values.state}>
                        <option defaultValue>Select State*</option>
                        {regional && Array.from(new Set(regional.map(obj => obj.State))).map((State, index) => {
                            return (<option value={State} key={index}>{State}</option>)
                        })}
                    </select>
                    {formik.errors.state? <span className='text-danger mt-2 d-block'>{formik.errors.state}</span> : null}
                </div>
                <div className='mb-3'>
                    <select className="form-select rounded-0" name="district"  onChange={formik.handleChange} value={formik.values.district}>
                        <option defaultValue>Select District*</option>
                        {
                            district.map((edist, index) => {
                                return (
                                    <option key={index}>{edist.District}</option>
                                )
                            })
                        }
                    </select>
                    {formik.errors.district? <span className='text-danger mt-2 d-block'>{formik.errors.district}</span> : null}
                </div>
                <div className="mb-3">
                    <div className='position-relative'>
                        <input type="text" className="form-control rounded-0" value={formik.values.city} onChange={formik.handleChange} name="city" placeholder="Enter City"/>
                        <div className='icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M192 107v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12zm116-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm-128 96h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm128 0h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm-128 96h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm128 0h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm140 205v20H0v-20c0-6.6 5.4-12 12-12h20V24C32 10.7 42.7 0 56 0h336c13.3 0 24 10.7 24 24v456h20c6.6 0 12 5.4 12 12zm-64-12V32H64v448h128v-85c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v85h128z"/></svg>
                        </div>      
                    </div>
                    {formik.errors.city? <span className='text-danger mt-2 d-block'>{formik.errors.city}</span> : null}
                </div>
                <div className="mt-4 d-flex gap-3 justify-content-center">
                    <input type="submit" className="btn w-100 rounded-0 bg-theme border-0 py-2 text-uppercase" value="Submit" />
                </div>
            </form>  
        </div> 
    </>
  )
}

export default VnextSolutionsFormComp