import './PageNotFoundComp.css';
import vnextError from '../../../../assets/images/404/404.png';


const PageNotFoundComp = ({title,desc,link,linkTitle}) => {

    return (
        <>
            <section className='page-not-found d-flex align-items-center justify-content-center'>
                <div className='content text-center'>
                    <img src={vnextError} className='img-fluid' alt=""/>
                    <h1 className='title'>{title}</h1>
                    <p className='desc mb-4'>{desc}</p>
                    <a className='btn' href={link}>{linkTitle}</a>
                </div>
            </section>
        </>
    );
}

export default PageNotFoundComp;