import React from 'react'
import HeaderComp from '../../common/header/HeaderComp'
import FooterComp from '../../common/footer/FooterComp'
import './Sitemap.css';
import { Link } from 'react-router-dom';

const Sitemap = () => {
  return (
    <>
        <HeaderComp/>
            <section className='py-5'>
                <div className='container'>
                    <div className='heading'>
                        <h2 className='fw-bold mb-4'>Sitemap</h2>
                    </div>
                    <div className='sitemap'>
                        <div className='sitemap-block'>
                            <h5 className='fw-bold mb-3 mb-4'>Applications</h5>
                            <h6 className='fw-bold mb-2'>Indoor:</h6>
                            <ul className='lh-lg mb-4'>
                                <li><Link to="https://vnext.in/applications" title="Best choice for false ceiling panels">Best choice for false ceiling panels</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Fiber cement panels for internal partitions">Fiber cement panels for internal partitions</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Fiber cement panel systems for your home">Fiber cement panel systems for your home</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Interiors with fiber cement panels for home">Interiors with fiber cement panels for home</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Easy to construct, durable mezzanine flooring">Easy to construct, durable mezzanine flooring</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Cost effective and durable fiber cement shelves">Cost effective and durable fiber cement shelves</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Fiber cement panels for interior construction">Fiber cement panels for interior construction</Link></li>
                            </ul>
                            <h6 className='fw-bold mb-3 mt-2'>Outdoor:</h6>
                            <ul className='lh-lg'>
                                <li><Link to="https://vnext.in/applications" title="Compound wall cladding with fiber cement boards">Compound wall cladding with fiber cement boards</Link></li>
                                <li><Link to="https://vnext.in/applications" title="External Cladding / Facade - Vnext">External Cladding / Facade &#8211; Vnext</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Aesthetically appealing fiber cement panels">Aesthetically appealing fiber cement panels</Link></li>
                                <li><Link to="https://vnext.in/applications" title="External cladding and facade with Fiber cement panels">External cladding and facadeFiber cement panels</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Fiber cement panel - False ceiling options">Fiber cement panel &#8211; False ceiling options</Link></li>
                                <li><Link to="https://vnext.in/applications" title="Fiber Cement Vplank for Soffit application">Fiber Cement Vplank for Soffit application</Link></li>
                            </ul>
                        </div>
                        <div className='sitemap-block'>
                            <h5 className='fw-bold mb-2'>Products</h5>
                            <ul className='lh-lg mb-4'>
                                <li><Link to="https://vnext.in/products/vboards" title="vboard">Vboard</Link></li>
                                <li><Link to="https://vnext.in/products/vplank" title="vplank">Vplank</Link></li>
                                <li><Link to="https://vnext.in/products/vpremium" title="vpremium">Vpremium</Link></li>
                                <li><Link to="https://vnext.in/products/vpanels" title="vpanel">Vpanel</Link></li>
                                <li><Link to="https://vnext.in/products/vframes" title="vframes">Vframes</Link></li>
                                <li><Link to="https://vnext.in/products" title="view all products">View All</Link></li>
                            </ul>
                        </div>
                        <div className='sitemap-block'>
                            <h5 className='fw-bold mb-2'>Solutions</h5>
                            <ul className='lh-lg mb-4'>
                                <li><Link to="https://vnext.in/solutions/cnc-cutting" title="cnc cutting">CNC Cutting</Link></li>
                                <li><Link to="https://vnext.in/solutions/false-ceiling" title="false ceiling">False Ceiling</Link></li>
                                <li><Link to="https://vnext.in/solutions/partitions" title="partitions">Partitions</Link></li>
                                <li><Link to="https://vnext.in/solutions/roof-underlay" title="roof underlay">Roof Underlay</Link></li>
                                <li><Link to="https://vnext.in/solutions/vplank-gate" title="vplank gate">Vplank Gate</Link></li>
                                <li><Link to="https://vnext.in/v-infill" title="vinfill">Vinfill</Link></li>
                                <li><Link to="https://vnext.in/solutions" title="view all products">View All</Link></li>
                            </ul>
                        </div>
                        <div className='sitemap-block'>
                            <h5 className='fw-bold mb-2'>Others</h5>
                            <ul className='lh-lg mb-4'>
                                <li><Link to="https://vnext.in/testimonials" title="testimonials">Testimonials</Link></li>
                                <li><Link to="https://vnext.in/clients" title="clients">Clients</Link></li>
                                <li><Link to="https://vnext.in/videos" title="media">Media</Link></li>
                                <li><Link to="https://vnext.in/contact" title="contact">Contact Us</Link></li>
                                <li><Link to="https://vnext.in/download-brochure" title="download brochure">Download Brochure</Link></li>
                                <li><Link to="https://vnext.in/certificates" title="certificates">Certificates</Link></li>
                                <li><Link to="https://vnext.in/privacy-policy" title="privacy policy">Privacy Policy</Link></li>
                                <li><Link to="https://vnext.in/disclaimer" title="disclamier">Disclaimer</Link></li>
                                <li><Link to="https://vnext.in/term-conditions" title="terms conditions">Terms Conditions</Link></li>
                                <li><Link to="https://vnext.in/cookie-policy" title="cookie policy">Cookie Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        <FooterComp/>
    </>
  )
}

export default Sitemap