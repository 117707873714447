import './DefaultSectionComponents.css';

const DefaultSectionComp = (props) => {
    return (
        <>
            <section className={`section ${props.className}`} style={props.customInlineStyles}>
                {props.children}
            </section>
        </>
    );
}
export default DefaultSectionComp;