import './HeaderComp.css';
import { Link } from 'react-router-dom';
import productsJson from '../../../../assets/data/products.json';
import solutionsJson from '../../../../assets/data/solutions.json';
import { CDN_URL } from '../../../../config/config';
const HeaderComp = () => {
    
    const productsList = productsJson.map((pl)=>{ return <li key={pl.key}><Link to={`/products/${pl.key}`} className="dropdown-item text-uppercase">{pl.title}</Link></li> });
    const solutionsList = solutionsJson.map((sl)=>{ return <li key={sl.key}><Link to={`/solutions/${sl.key}`} className="dropdown-item text-uppercase">{sl.title}</Link></li> });

 
    return (
        <>
            <header className='border-bottom'>
                <nav className="header-top bg-light border-bottom">
                    <div className='container'>
                        <ul className='nav d-flex align-items-center justify-content-center justify-content-xl-between'>
                            <li className="nav-item px-3 py-2 fw-semibold"><Link to="tel:918886786398" className='transition-effect text-decoration-none'>+91 8886786398</Link></li>
                            <li className="nav-item px-3 py-2 fw-semibold d-none d-xl-flex"><Link to="/certificates" className='transition-effect text-decoration-none'>We are <b className='text-success'>Green Pro</b> certified</Link></li>
                            <li className="nav-item px-3 py-2 fw-semibold d-none d-xl-flex"><Link to="mailto:support@vnext.in" className='transition-effect text-decoration-none'>support@vnext.in</Link></li>
                        </ul>
                    </div>
                </nav>
                <nav className="navbar header-bottom navbar-expand-xl bg-white py-0">
                    <div className="container">
                        <Link to="/" className="navbar-brand py-2"><img src={CDN_URL+'visaka/vnext/website/assets/images/logo.png'} alt="Logo" /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#headerMenuNavbar" aria-controls="headerMenuNavbar" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                        <div className="collapse navbar-collapse" id="headerMenuNavbar">
                            <ul className="navbar-nav ms-auto mb-3 mb-xl-0">
                                <li className="nav-item"><Link to="/" className="nav-link text-decoration-none transition-effect text-uppercase active" aria-current="page">Home</Link></li>
                                <li className="nav-item dropdown">
                                    <Link to="/products" className="nav-link dropdown-toggle text-decoration-none transition-effect text-uppercase" role="button" data-bs-toggle="dropdown" aria-expanded="false">Products</Link>
                                    <ul className="dropdown-menu mt-0 shadow border-0">
                                        {productsList}
                                        <li key={'all'}><Link to={`/products`} className="dropdown-item text-uppercase">{'View all'}</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link to="/solutions" className="nav-link dropdown-toggle text-decoration-none transition-effect text-uppercase" role="button" data-bs-toggle="dropdown" aria-expanded="false">Solutions</Link>
                                    <ul className="dropdown-menu mt-0 shadow border-0">
                                        {solutionsList}
                                        <li key={'all'}><Link to={`/solutions`} className="dropdown-item text-uppercase">{'View all'}</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/applications" className="nav-link text-decoration-none transition-effect text-uppercase">Applications</Link>
                                </li>
                                <li className="nav-item"><Link to="/blogs/1" className="nav-link text-decoration-none transition-effect text-uppercase">Blog</Link></li>
                                <li className="nav-item"><Link to="/coverages" className="nav-link text-decoration-none transition-effect text-uppercase">Coverages</Link></li>
                                <li className="nav-item"><Link to="/videos" className="nav-link text-decoration-none transition-effect text-uppercase">Gallery</Link></li>
                            </ul>
                            <form className="d-flex ms-0 mb-3 mb-xl-0" role="search">
                                <Link to="/contact" className="btn btn-warning theme-button text-decoration-none transition-effect text-uppercase fw-semibold border-0 rounded-1 m-0 w-100" type="submit">Contact Us</Link>
                            </form>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}
export default HeaderComp;