import React from 'react'

const ApplicationNavbarComp = ({vnextApplications, updateApplicationId}) => {

    const indoorNavElements = vnextApplications.indoor.map((e)=>{ return <li className="nav-item d-flex gap-2 align-items-center" key={e.id}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{width: '10px', fill: '#666'}}><path d="M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"></path></svg><button className="nav-link" onClick={()=>updateApplicationId(['indoor',e.id])}>{e.title}</button></li>; });
    const outdoorNavElements = vnextApplications.outdoor.map((e)=>{ return <li className="nav-item d-flex gap-2 align-items-center" key={e.id}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{width: '10px', fill: '#666'}}><path d="M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"></path></svg><button className="nav-link" onClick={()=>updateApplicationId(['outdoor',e.id])}>{e.title}</button></li>; });

    return (
        <div className='applications-navbar bg-light position-sticky top-0 p-4 shadow'>
            <ul className="list-unstyled mb-3">
                <li className='mb-4'>
                    <h6 className='fw-bold mb-2'>Indoor</h6>
                    <ul className="list-unstyled">{indoorNavElements}</ul>
                </li>
                <li>
                    <h6 className='fw-bold mb-2'>Outdoor</h6>
                    <ul className="list-unstyled">{outdoorNavElements}</ul>
                </li>
            </ul>
        </div>
    )
}

export default ApplicationNavbarComp