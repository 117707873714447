import { useState, useEffect }  from 'react';
import { useLocation } from "react-router-dom";

import './PageResetComp.css';

const PageResetComp = () => {
    const [fadeOutStatus, setFadeOutStatus] = useState(false);
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setFadeOutStatus(false)
    }, [pathname]);

    setTimeout(function () { setFadeOutStatus((e)=>{ return true; }); }, 1000);

    return (
        <div className={`spinner-container${((fadeOutStatus === true) ? ' loading_effect' : '')}`} id="loadingEff"><div className="loading-spinner"></div></div>
    )
}

export default PageResetComp