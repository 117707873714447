import './DefaultSectionComponents.css';

const DefaultSectionBodyComp = ({ children, className }) => {

    return (<>
        <div className={`section-body ${className}`}>
            {children}
        </div>
    </>);
}

export default DefaultSectionBodyComp;