import React from 'react'
import './VideosComp.css';
import HeaderComp from '../../common/header/HeaderComp'
import FooterComp from '../../common/footer/FooterComp'

const VideosComp = () => {
  return (
    <>  
        <HeaderComp/>
        <div className='py-5 text-center'>
            <div className='container'>
                <div className='row'>
                    <div className='videos'>
                        <div className='col-12'>
                            <div>
                                <iframe src="https://www.youtube.com/embed/QNPOsBKs92E" className="rounded shadow w-75" height="550" frameborder="0" allowfullscreen="allowfullscreen" title="taking vamshi sir about product"></iframe>
                            </div>
                        </div>
                        <div className='row d-flex flex-wrap gy-3 py-5'>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/ILX0AAf3yko" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/SZXee3L-iQQ?rel=0" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/3NASJjhbLkM" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/SfcLlBcpQJ0" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/jma3gOpnJqw?rel=0" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/s3lb1qzMoxQ?rel=0" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/64fepExQsyY" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/osK8gP2Vi5A" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/eeZtYw1HeH8" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/snUPX16kTzY" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/DXpb8E8CFNc" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/F698d_h-CEY" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/X9PB3Vh6GhA" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/ufMC1S3mX-M" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/tM4rB3rf5Jg" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3'>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/E6EYR-Bt_WY" className="rounded shadow w-100" height="170" frameborder="0" allowfullscreen="allowfullscreen" title="video"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterComp/>
    </>
  )
}

export default VideosComp