import { useState } from "react";
import './ApplicationsComp.css';
import vnextApplications from '../../../../assets/data/applications.json';
import ApplicationNavbarComp from './ApplicationNavbarComp';
import ApplicationDetailsComp from './ApplicationDetailsComp';
import DefaultSectionComp from "../../common/default-section-components/DefaultSectionComp";
import HeaderComp from "../../common/header/HeaderComp";
import FooterComp from "../../common/footer/FooterComp";

const ApplicationsComp = () => {
    const [applicationId, setApplicationId] = useState(['indoor','kitchen-cabinets-and-shelves']);
    
    const applicationDetails = vnextApplications[applicationId[0]].filter((e) => { return (e['id'] === applicationId[1]); })[0];
    
    const updateApplicationId = (id) => { setApplicationId(()=>{ return id; }) }
    
    return (<>
        <HeaderComp/>
        <DefaultSectionComp className="section">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-4 col-xl-4"><ApplicationNavbarComp vnextApplications={vnextApplications} updateApplicationId={updateApplicationId} /></div>
                    <div className="col-12 col-lg-8 col-xl-8"><ApplicationDetailsComp applicationDetails={applicationDetails} /></div>
                </div>
            </div>
        </DefaultSectionComp>
        <FooterComp/>
    </>)
}

export default ApplicationsComp