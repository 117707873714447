import { Route, Routes } from 'react-router-dom';

import { WebsiteComp } from './components/website/WebsiteComp';

import PageResetComp from './components/website/common/page-reset/PageResetComp';
import HomeComp from './components/website/pages/home/HomeComp';
import ProductsComp from './components/website/pages/products/ProductsComp';
import SolutionsComp from './components/website/pages/solutions/SolutionsComp';
import SuccessEnqComp from './components/website/common/forms/SuccessEnqComp';
import Clients from './components/website/pages/Clients';
import Testimonials from './components/website/pages/Testimonials';
import Certificates from './components/website/pages/Certificates';
import DownloadBrochure from './components/website/pages/DownloadBrochure';
import PrivacyPolicy from './components/website/pages/PrivacyPolicy';
import TermsConditions from './components/website/pages/TermsConditions';
import Disclaimer from './components/website/pages/Disclaimer';
import CookiePolicy from './components/website/pages/CookiePolicy';
import Sitemap from './components/website/pages/sitemap/Sitemap';
import ContactComp from './components/website/pages/ContactComp';
import ApplicationsComp from './components/website/pages/applications/ApplicationsComp';
import BlogsComp from './components/website/pages/blogs/BlogsComp';
import CoveragesComp from './components/website/pages/coverages/CoveragesComp';
import VideosComp from './components/website/pages/gallery/VideosComp';
import VnextSolutionsComp from './components/landing-pages/vnext-solutions/VnextSolutionsComp';
import ErrorEnqComp from './components/website/common/forms/ErrorEnqComp';
import TagManager from 'react-gtm-module';

function App() {
    const tagManagerArgs = { gtmId: 'AW-975732568' }
    TagManager.initialize(tagManagerArgs);
    return (
        <div className="App">
            <PageResetComp />
            <Routes>
                <Route path='/' element={<WebsiteComp webComp={<HomeComp />}  />} />
                <Route path='/products' element={<WebsiteComp webComp={<ProductsComp />}  />} />
                <Route path='/products/:paramPId' element={<WebsiteComp webComp={<ProductsComp />}  />} />
                <Route path='/solutions' element={<WebsiteComp webComp={<SolutionsComp />} />} />
                <Route path='/solutions/:paramSId' element={<WebsiteComp webComp={<SolutionsComp />} />} />
                <Route path='/applications' element={<ApplicationsComp webComp={<ApplicationsComp defaultApplicationType="indoor" defaultApplicationId="kitchen-cabinets-and-shelves" />} />}/>
                <Route path='/blogs' element={<WebsiteComp webComp={<BlogsComp />} />} />
                <Route path='/blogs/:paramBPageNum' element={<WebsiteComp webComp={<BlogsComp />} />} />
                <Route path='/blog/:paramBId' element={<WebsiteComp webComp={<BlogsComp />} />} />
                <Route path='/coverages' element={<WebsiteComp webComp={<CoveragesComp />} />} />
                <Route path='/coverages/:paramCId' element={<WebsiteComp webComp={<CoveragesComp />} />} />
                <Route path='/videos' element={<WebsiteComp webComp={<VideosComp />} />} />
                <Route path='/contact' element={<WebsiteComp webComp={<ContactComp />} />} />
                <Route path='/success' element={<WebsiteComp webComp={<SuccessEnqComp />} />} />
                <Route path='/error' element={<WebsiteComp webComp={<ErrorEnqComp />} />} />
                <Route path='/clients' element={<WebsiteComp webComp={<Clients />} />} />
                <Route path='/testimonials' element={<WebsiteComp webComp={<Testimonials />} />} />
                <Route path='/certificates' element={<WebsiteComp webComp={<Certificates />} />} />
                <Route path='/download-brochure' element={<WebsiteComp webComp={<DownloadBrochure />} />} />
                <Route path='/privacy-policy' element={<WebsiteComp webComp={<PrivacyPolicy />} />} />
                <Route path='/term-conditions' element={<WebsiteComp webComp={<TermsConditions />}  />} />
                <Route path='/disclaimer' element={<WebsiteComp webComp={<Disclaimer />} />} />
                <Route path='/cookie-policy' element={<WebsiteComp webComp={<CookiePolicy />} />} />
                <Route path='/sitemap' element={<WebsiteComp webComp={<Sitemap />} />} />
                <Route path='/vnext_solutions' element={<VnextSolutionsComp/>} />
            </Routes>
        </div>
    );
}

export default App;
