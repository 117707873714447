import React from 'react'
import HeaderComp from '../common/header/HeaderComp'
import FooterComp from '../common/footer/FooterComp'

const CookiePolicy = () => {
    return (
        <div>
            <HeaderComp />
            <section className='py-5 lh-lg'>
                <div className='container'>
                    <div className='heading'>
                        <h2 className='fw-bold mb-4'>Cookie Policy</h2>
                    </div>
                    <h6 className='fw-bold'>Updated at 2021-10-16</h6>

                    Definitions and key terms

                    To help explain things as clearly as possible in this Cookie Policy, every time any of these terms are referenced, are strictly defined as:
                    <h5 className='my-3 fw-bold'>
                        Cookie: </h5>small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.
                    <h5 className='my-3 fw-bold'>Company:</h5> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Vnext by visaka, that is responsible for your information under this Cookie Policy.
                    <h5 className='my-3 fw-bold'>Device:</h5>  any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Vnext by visaka and use the services
                    <h5 className='my-3 fw-bold'>Personal Data: </h5> any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
                    <h5 className='my-3 fw-bold'>Service: </h5> refers to the service provided by Vnext by visaka as described in the relative terms (if available) and on this platform.
                    <h5 className='my-3 fw-bold'>Third-party service:</h5>  refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.
                    <h5 className='my-3 fw-bold'>Website: </h5> Vnext by visaka.”’s” site, which can be accessed via this URL: https://vnext.in/
                    <h5 className='my-3 fw-bold'>You:</h5>  a person or entity that is registered with Vnext by visaka to use the Services.
                    <h4 className='fw-bold mb-3 mt-3'>Introduction</h4>

                    This Cookie Policy explains how Vnext by visaka and its affiliates (collectively “Vnext by visaka”, “we”, “us”, and “ours”), use cookies and similar technologies to recognize you when you visit our website, including without limitation https://vnext.in/ and any related URLs, mobile or localized versions and related domains / sub-domains (“Websites”). It explains what these technologies are and why we use them, as well as the choices for how to control them
                    <h4 className='fw-bold mb-3 mt-3'>
                        What is a cookie?
                    </h4>
                    A cookie is a small text file that is stored on your computer or other internet connected device in order to identify your browser, provide analytics, remember information about you such as your language preference or login information. They’re completely safe and can’t be used to run programs or deliver viruses to your device.
                    <h4 className='fw-bold mb-3 mt-3'>
                        Why do we use cookies?
                    </h4>
                    We use first party and/or third party cookies on our website for various purposes such as:


                    <ul>
                        <li>To facilitate the operation and functionality of our website;</li>
                        <li>To improve your experience of our website and make navigating around them quicker and easier;</li>
                        <li>To allow us to make a bespoke user experience for you and for us to understand what is useful or of interest to you;</li>
                        <li>To analyze how our website is used and how best we can customize it;</li>
                        <li>To identify future prospects and personalize marketing and sales interactions with it;</li>
                        <li>To facilitate the tailoring of online advertising to your interests.</li>
                    </ul>

                    <h4 className='fw-bold mb-3 mt-3'>

                        What type of cookies does Vnext by visaka use?</h4>

                    Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you’re visiting are called “first party cookies”.

                    Strictly Necessary cookies are necessary for our website to function and cannot be switched off in our systems. They are essential in order to enable you to navigate around the website and use its features. If you remove or disable these cookies, we cannot guarantee that you will be able to use our website.

                    We use the following types of cookies in our website:

                    <h4 className='fw-bold mb-3 mt-3'>
                        Essential Cookies
                    </h4>
                    We use essential cookies to make our website work. These cookies are strictly necessary to enable core functionality such as security, network management, your cookie preferences and accessibility. Without them you wouldn’t be able to use basic services. You may disable these by changing your browser settings, but this may affect how the Websites function.
                    <h4 className='fw-bold mb-3 mt-3'>
                        Performance and Functionality Cookies
                    </h4>
                    These cookies are used to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the website as we would not be able to remember that you had logged in previously.
                    <h4 className='fw-bold mb-3 mt-3'>
                        Marketing Cookies
                    </h4>
                    These account-based marketing cookies enable us to identify future prospects and personalize sales and marketing interactions with them.
                    <h4 className='fw-bold mb-3 mt-3'>

                        Analytics and Customization Cookies
                    </h4>
                    These cookies collect information that is used to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our website for you.

                    We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable us to better understand your use of our website. Further information on how Google collects and uses this data can be found at: https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported analytics on our Websites by visiting: https://tools.google.com/dlpage/gaoptout.
                    <h4 className='fw-bold mb-3 mt-3'>
                        Social Media Cookies
                    </h4>
                    These cookies are used when you share information using a social media sharing button or “like” button on our Site or you link your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social network will record that you have done this. These cookies may also include certain code that has been placed on the platform to help track conversions from ads, optimize ads based on collected data, build targeted audiences for future ads, and remarket to qualified users who have already taken certain action on the platform.
                    <h4 className='fw-bold mb-3 mt-3'>

                        Third Party Cookies
                    </h4>
                    Some cookies that have been set on our website are not set on a first party basis by Vnext by visaka. The Websites can be embedded with content from third parties to serve advertising. These third party service providers may set their own cookies on your web browser. Third party service providers control many of the performance and functionality, advertising, marketing and analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by the third party that originally set them.
                    <h4 className='fw-bold mb-3 mt-3'>
                        How you can manage cookies?
                    </h4>
                    Most browsers allow you to control cookies through their ‘settings’ preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information. Browser manufacturers provide help pages relating to cookie management in their products.

                    Browser manufacturers provide help pages relating to cookie management in their products. Please see below for more information.
                    <ul>
                        <li>Google Chrome</li>
                        <li>Internet Explorer</li>
                        <li>Mozilla Firefox</li>
                        <li>Safari (Desktop)</li>
                        <li>Safari (Mobile)</li>
                        <li>Android Browser</li>
                        <li>Opera</li>
                        <li>Opera Mobile</li>
                    </ul>


                    <h4 className='fw-bold mb-3 mt-3'>Blocking and disabling cookies and similar technologies</h4>



                    Wherever you’re located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser’s help menu for more information
                    <h4 className='fw-bold mb-3 mt-3'>
                        Changes To Our Cookie Policy</h4>

                    We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Cookie Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want to agree to this or any updated Cookie Policy, you can delete your account.
                    <h4 className='fw-bold mb-3 mt-3'>
                        Your Consent
                    </h4>
                    By using our website, registering an account, or making a purchase, you hereby consent to our Cookie Policy and agree to its terms.
                    <h4 className='fw-bold mb-3 mt-3'>
                        Contact Us
                    </h4>
                    Don’t hesitate to contact us if you have any questions.

                    Via Email : vnext@visaka.in<br />
                    Via Phone Number : +91 88867 86398<br />
                    Via this Link : https://vnext.in/<br />
                    Via this Address : Visaka Towers, 1-8-303/69/3 S.P. Road, Secunderabad Andhra Pradesh, India – 500003<br />
                </div>
            </section>

            <FooterComp />
        </div>
    )
}

export default CookiePolicy