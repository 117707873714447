import React from 'react'
import { Link } from 'react-router-dom'
import './FormComp.css'

const ErrorEnqComp = () => {
  return (
    <div>
        <div className='errorForm'>
            <div className='position-absolute top-50 start-50 translate-middle text-center shadow p-5 rounded'>
                <div className='icon mb-4'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 40c118.6 0 216 96.1 216 216 0 119.3-96.6 216-216 216-119.2 0-216-96.6-216-216 0-119.2 96.6-216 216-216m0-32C119 8 8 119.1 8 256c0 137 111 248 248 248s248-111 248-248C504 119.1 393 8 256 8zm-11.5 120h23c6.8 0 12.3 5.7 12 12.5l-7 168c-.3 6.4-5.6 11.5-12 11.5h-9c-6.4 0-11.7-5.1-12-11.5l-7-168c-.3-6.8 5.2-12.5 12-12.5zM256 340c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28z"/></svg>
                </div>
                <div className='details'>
                    <h2>Error</h2>
                    <p>Thank you for showing the interest an enquiry. <br/> Our Team will connect with you.</p>
                </div>
                <div className='goToHomeBtn rounded  py-2 px-3'>
                    <Link to='/' className='text-decoration-none text-white'>Go To Home Page</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ErrorEnqComp