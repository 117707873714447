import { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import './BlogsComp.css';
import HeaderComp from '../../common/header/HeaderComp';
import PageBannerComp from '../../common/page-banner/PageBannerComp';
import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';
import FooterComp from '../../common/footer/FooterComp';
import BlogsListComp from './BlogsListComp';
import BlogDetailsComp from './BlogDetailsComp';
import PageNotFoundComp from '../../common/404/PageNotFoundComp';
import { useParams } from "react-router-dom";

import blogsJson from '../../../../assets/data/blogs.json'
import { CDN_URL } from '../../../../config/config';

const BlogsComp = () => {
    const {paramBPageNum, paramBId} = useParams();

    const [bId, setBId] = useState('');
    const [currentPageNum, setCurrentPageNum] = useState(1);

    useEffect(()=>{ if(typeof paramBPageNum != 'undefined'){ setBId(''); setCurrentPageNum(paramBPageNum); } },[paramBPageNum]);
    useEffect(()=>{ if(typeof paramBId != 'undefined'){ setCurrentPageNum(0); setBId(paramBId); } },[paramBId]);

    let pageStatus = true;
    let pageContent = <PageNotFoundComp title = {"Page not Found!"} desc = {"The page you are trying to access is not found, please try again."} link={"/"} linkTitle={"Go to Home"} />;
    let bannerDetails = {
        "title": "Blog's",
        "tagline": "Brilliant, Modern and Utilitarian",
        "desc": "Fibre cement boards are being used in some of the most iconic buildings of our time because they save time and money. They are safer and are aesthetically more beautiful.",
        "image": "visaka/vnext/website/assets/images/bg.jpg"
    };
    
    if(bId !== ""){
        let bIdStatus = false;
        let bIdDetails = false;

        blogsJson.map((b) => { if(b.key === bId){ bIdStatus = true; bIdDetails = b; } return true;});
        
        if(bIdStatus === true){
            pageContent = <BlogDetailsComp bDetails={bIdDetails} />
            bannerDetails.title = bIdDetails.title;
            bannerDetails.tagline = bIdDetails.tagline;
            bannerDetails.desc = bIdDetails.desc;
            bannerDetails.image = bIdDetails.background_image;
		}else{ pageStatus = false; }
    }else{
        const recordsPerpage = 9;
        const lastIndex = currentPageNum * recordsPerpage;
        const firstIndex = lastIndex - recordsPerpage;
        const blogsJsonData = blogsJson.slice(firstIndex, lastIndex);
        const npage = Math.ceil(blogsJson.length / recordsPerpage);
        const paginationNumbers = [...Array(npage + 1).keys()].slice(1);
        
        pageContent = <BlogsListComp currentPageNum={currentPageNum} blogsJsonData={blogsJsonData} paginationNumbers={paginationNumbers} />;
	}
    return (<>
        <HeaderComp />
        {pageStatus && < PageBannerComp className = {'page-banner-comp'} bType = "banner" bDetails = {bannerDetails} />}
        {pageContent}
        {pageStatus && < DefaultSectionComp className = "section bg-img home-section-join-hands" customInlineStyles={{backgroundImage:`url(${CDN_URL}visaka/vnext/website/assets/images/bg2.jpg)`}}>
			<div className = 'container pb-5 mb-5' >
                <div className = "text-center home-section-join-hands-content pb-5 mb-5" >
                    <h2 className = 'title' > JOIN HANDS IN BUILDING < br /> AN <b className = 'color-green' > ECO - FRIENDLY </b> FUTURE</h2>
                    <p className = 'desc mb-5 pb-5' > <q> SMALL ACTS, WHEN MULTIPLIED BY MILLIONS OF PEOPLE, CAN TRANSFORM THE WORLD. </q></p>
                </div>
            </div>
        </DefaultSectionComp>}
        <FooterComp />
	</>);
}
export default BlogsComp;