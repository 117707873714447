import './WebsiteComp.css'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'

export const WebsiteComp = ({webComp}) => {
  return (
    <>
      {webComp}
    </>
  )
}
