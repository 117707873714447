import React from 'react'
import HeaderComp from '../common/header/HeaderComp'
import FooterComp from '../common/footer/FooterComp'

const Disclaimer = () => {
    return (
        <div>
            <HeaderComp />

            <section className='py-5 lh-lg'>
                <div className='container'>

                    <div className='heading'>
                        <h2 className='fw-bold mb-4'>Disclaimer</h2>
                    </div>



                    <p>Any person logging on to or using the site (“the Visitor”) has unconditionally accepted the terms and conditions of use and these constitute a binding and enforceable agreement between the visitor and Vnext.
                    </p>

                    <p>
                        The information on this website is presented as general information and no representation or warranty is expressly or impliedly given as to it accuracy, completeness or correctness.

                    </p>
                    <p>


                        The visitor is presumed to have read the terms and conditions of the website and is deemed to have agreed, understood and accepted unconditionally all the terms, conditions, procedure and risks of logging onto the website and cannot claim, at anytime, ignorance of any or all of them.
                    </p>

                    <p>

                        Vnext uses all diligence, skill and expertise available to provide information on this website but does not accept or undertake any express or implied warranty of any nature whatsoever and Vnext disclaims all or any errors and mistakes to the fullest extent. Vnext does not warrant that the information offered will be error-free, or that the defects will be corrected, or that this site or the server that makes it available are or will be free of viruses or other harmful components. Vnext shall not be under any obligation to ensure compliance or handle complaints.</p>

                    <p>
                        The plans, specifications, images and other details herein are only indicative and Vnext reserves the right to change any or all of these in the interest of the project/development. The Website does not constitute an offer and/or contract of any nature whatsoever. Any purchase/leave and license in any project shall be governed by the terms of the agreement entered into between the parties and no details mentioned on this website shall govern the transaction. Vnext and its respective subsidiaries and affiliates, and their respective officers, directors, partners, employees, agents, managers, trustees, representatives or contractors of any of them, related thereto and any successors or assigns of any of the foregoing shall not be liable for any direct, indirect, actual, punitive, incidental, special, consequential damages or economic loss whatsoever, arising from or related to the use of or reliance on this Web site.
                    </p>

                    <p>
                        The visitor has, by the act of logging onto the website and/or submitting information or giving his name, address, email addresses as identification to Vnext through the website, on phone, fax or e-mail, deemed to have consented and has expressly and irrevocably authorized Vnext to use, reveal, analyze or display and transmit all information and documents as may be required by it. The visitor represents and warrants that he has provided true, accurate, current and complete information about himself and if any information is found to be untrue, inaccurate, not current or incomplete, Vnext has the right to take any action it deems appropriate without any limitation.
                        The visitor represents and warrants that he/she is fully aware of the laws of the country/state he/she resides in and also those of India particularly those governing use, sale, lease, transfer of real estate and the visitor is neither violating nor attempting to violate any law.
                    </p>

                    <p>
                        The contents, information and material contain in this website are the exclusive property of Vnext and are protected by copyright and intellectual property laws. No person shall use, copy, reproduce, distribute, initiate, publish, display, modify, create derivative works or database, use, transmit, upload, exploit, sell or distribute the same in whole or in part or any part thereof without prior express written permission from Vnext. Facility to print an article or portion of text or material on this website through computer/electronic device does not tantamount to prior written consent.
                        Notwithstanding anything stated hereinabove or in this website, it is clarified, understood and agreed that Vnext through this website does not intend to make any offer, proposal or contract as per prevailing laws in India or any similar or relevant law in the country of residence or access of the visitor.
                    </p>

                    <p>
                        Vnext has the right to reproduce, monitor, disclose any transmission or information received and made to this website. Visitors may be sent information or contacted through the email addresses, phone numbers and postal addresses provided by the visitor on the website. Any visitor who may not desire to receive email from Vnext may give clear instructions.</p>
                    The visitor shall not use or post any computer programs in connection with his/her use of the website that contain destructive features such as viruses anomalies, self-destruct mechanisms, time/logic bombs, worm, Trojan horses etc.

                    <p>
                        Notwithstanding anything, in no event shall Vnext, their promoters, directors, employees and agents be liable to the visitor for any or all damages, losses and causes of action (including but not limited to negligence), errors, injury, whether direct, indirect, consequential or incidental, suffered or incurred by any person/s or due to any use and/or inability to use this site or information or its links, hyperlinks, action taken or abstained or any transmission made through this website.
                        Any links to off-site pages or other sites may be accepted by the visitor at his/her option and own risk and Vnext assumes no liability for and shall be held harmless from any resulting damages. Vnext strongly recommends that the visitors carefully reads the terms and conditions of such linked site(s).
                        Vnext reserves the right to terminate, revoke, modify, alter, add, and delete any one or more of the terms and conditions of the website. Vnext shall be under no obligation to notify the visitor of the amendment to the terms and conditions and the visitor shall be bound by such amended terms and conditions.
                        Product cost listed on the website is indicative in nature, actual product cost might be different.</p>
                </div>
            </section>
            <FooterComp />
        </div>
    )
}

export default Disclaimer