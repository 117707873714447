import React from 'react'
import HeaderComp from '../common/header/HeaderComp'
import FooterComp from '../common/footer/FooterComp'

const Testimonials = () => {
  return (
    <div>
        <HeaderComp/>
        <section>
            <div className='container'>
                <div className='row py-5'>
                    <div className='testimonials d-flex flex-wrap'>
                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3'>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>Our sites at Naidupet, Vizag and Hyderabad are now powered by Vpanel. We have used it in our site offices and accommodation shelters and we think it’s the best. We are highly impressed by its performance and convenience. We are definitely going to consider them in the future as well.</p>
                                    <p className='author'>- lixir Met Form Pvt. Ltd.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3 '>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>The Shamshabad international airport has been using Vpanels and it has worked like a charm. The ecofriendly attributes of Vpanels are exactly what we needed.</p>
                                    <p className='author'>- Vara Lakshmi Foundation (GMR group).</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3'>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>Vpanel has been a part of our thermal power tech products. We are markedly impressed by the efficient operation of its thermal properties. The operational strength of Vpanel is commendable.</p>
                                    <p className='author'>- Gayatri Projects.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3 '>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>Vpanel has been extensively used in the construction of our prefab structures all over the country, including projects for Reliance. Vpanel is lightweight and flexible and its efficient quality has helped us save a lot of time and money..</p>
                                    <p className='author'>- REF Technologies</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3 '>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>We are please to inform you that we have used Vpanel manufactured by Visaka Industries Miryalaguda for the construction of internal wall partitions. We found that Vpanel product satisfied in our usage.</p>
                                    <p className='author'>- TKR Institutions, Meerpet</p>
                                </div>
                            </div>
                        </div>


                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3 '>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>We used the product Vboard (Non-Asbestos fibre cement boards) manufatured by Visaka Industries Limited, 12MM thick in Ramanujan IT park (TRIL) Chennai Project for the backliners in Aluminium Composite Panels. The porduct is good as per the Expectation.</p>
                                    <p className='author'>- Bhari Metal Fabrication Pvt. Ltd.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3 '>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>We would like to inform you regarding using of Vpanels for Vara Lakshmi Foundations (GMR group) at Shamshabad international airport.</p>
                                    <p className='author'>- Micon Constructions</p>
                                </div>
                            </div>
                        </div>


                        <div className='col-12 col-md-12 col-lg-6 col-xl-6 px-2 mb-3 '>
                            <div className='testimonial-box border shadow  rounded details d-flex gap-4 align-items-center p-4'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM65 464H383L348.5 352H99.5L65 464zM64 304H384l49.2 160L448 512H397.8 50.2 0l14.8-48L64 304z"/></svg>
                                </div>
                                <div className='content'>
                                    <p>We have used Vpanels for a Villa project at Tripunithura, as an extension for the outhouse portion. I feel that Vpanels are very ease in handling, and the weight is less compared to the other products in market. Its quiet strong though. Even the workers feel more comfortable in using Vpanels. It’s an efficient product for easy and fast installation, at most when there is a quick requirement of sturdy walls or partitions. Especially We like to congratulate Visaka Industries Limited for finishing the work prior to their commitments.</p>
                                    <p className='author'>-Ebony Interior Solutions</p>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
            </div>
        </section>
        <FooterComp/>
    </div>
  )
}

export default Testimonials