import './DefaultSectionComponents.css';

const DefaultSectionHeadingComp = ({ children, className }) => {

    let borderEffectItems = [];
    for (let i = 0; i < 5; i++){
        let borderEffectItemsClassNames = [];
        (i === 0 || i === 4) && borderEffectItemsClassNames.push("w-8");
        (i === 1 || i === 3) && borderEffectItemsClassNames.push("w-16");
        (i === 2) && borderEffectItemsClassNames.push("w-52");
        borderEffectItems.push(<span key={i} className={`h-100 rounded-1 ${borderEffectItemsClassNames.join(" ")}`}></span>);
    }

    return (<>
        <div className={`section-heading ${className} mb-4`}>
            <div className='section-heading-items position-relative pb-2'>
                {children}
                <div className='border-effect position-absolute w-75 start-50 translate-middle-x bottom-0 d-flex justify-content-between gap-2 overflow-hidden'>
                    {borderEffectItems}
                </div>
            </div>
        </div>
    </>);
}

export default DefaultSectionHeadingComp;